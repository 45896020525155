<template>
  <div>

    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="transferRequestModal">
      
      <h4 class="text-xl font-bold mb-16">Request Transfer</h4>

      <template v-if="getFormError(transferForm)">
        <div class="alert alert-red-soft mb-16">
          <div class="alert-icon">!</div>
          <span>{{ getFormError(transferForm) }}</span>
        </div>
      </template>

      <form @submit.prevent="setTransfer">

        <form-group
          left-icon="pricetag-outline"
          name="label"
          :form="transferForm"
          v-model="transferForm.data.label.value"
        >
          Transfer Label
        </form-group>

        <form-group
          type="select"
          :options="beneficiaryOptions"
          left-icon="cash-outline"
          name="beneficiary"
          :loading="beneficiaries.loading"
          :form="transferForm"
          v-model="transferForm.data.beneficiary.value"
        >
          Beneficiary
        </form-group>
        <div class="-mt-6 mb-6">
          <a href="#!" class="text-sm text-gray-700" @click.prevent="addBeneficiary">+ Add Beneficiary</a>
        </div>

        <form-group
          type="textarea"
          left-icon="reader-outline"
          name="description"
          :form="transferForm"
          v-model="transferForm.data.description.value"
        >
          Payment Description
        </form-group>

        <form-group
          type="money"
          left-icon="wallet-outline"
          name="amount"
          :form="transferForm"
          v-model="transferForm.data.amount.value"
        >
          Amount
        </form-group>

        <form-group
          type="select"
          :options="subCategoryOptions"
          left-icon="albums-outline"
          name="category"
          :loading="categories.loading"
          :form="transferForm"
          v-model="transferForm.data.category.value"
        >
          Expense Sub Category
        </form-group>
        <div class="-mt-6 mb-6">
          <a href="#!" class="text-sm text-gray-700" @click.prevent="addCategory">+ Add Sub Category</a>
        </div>

        <form-group
          type="file"
          left-icon="receipt-outline"
          name="invoice"
          :form="transferForm"
          v-model="transferForm.data.invoice.value"
        >
          Upload Invoice (Optional)
        </form-group>

        <div class="text-center">
          <button type="submit" class="btn btn-blue btn-md px-20" :disabled="transferForm.loading">
            Submit
          </button>
        </div>

      </form>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="beneficiaryModal">
      
      <h4 class="text-xl font-bold mb-16">Add Beneficiary</h4>

      <form @submit.prevent="submitBeneficiary">

        <template v-if="getFormError(beneficiaryForm)">
          <div class="alert alert-red-soft mb-16">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(beneficiaryForm) }}</span>
          </div>
        </template>

        <form-group
          left-icon="person-outline"
          name="name"
          :form="beneficiaryForm"
          v-model="beneficiaryForm.data.name.value"
        >
          Beneficiary Name
        </form-group>

        <form-group
          left-icon="locate-outline"
          name="account_number"
          :form="beneficiaryForm"
          v-model="beneficiaryForm.data.account_number.value"
        >
          Beneficiary Account Number
        </form-group>

        <form-group
          type="select"
          :options="banks"
          left-icon="business-outline"
          name="bank_id"
          :form="beneficiaryForm"
          v-model="beneficiaryForm.data.bank_id.value"
        >
          Beneficiary Bank
        </form-group>

        <form-group
          type="textarea"
          left-icon="reader-outline"
          name="description"
          :form="beneficiaryForm"
          v-model="beneficiaryForm.data.description.value"
        >
          Beneficiary Description
        </form-group>
        
        <div class="text-center">
          <button type="submit" class="btn btn-blue btn-md" :disabled="beneficiaryForm.loading">
            <span v-if="beneficiaryForm.loading">Saving</span>
            <span v-else>Save Beneficiary</span>
          </button>
        </div>

      </form>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="transferConfirmationModal">
      
      <h4 class="text-xl font-bold mb-6">Confirm Transfer</h4>

      <template v-if="getFormError(transferForm)">
        <div class="alert alert-red-soft mb-16">
          <div class="alert-icon">!</div>
          <span>{{ getFormError(transferForm) }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to send?</div>

      <div class="bg-blue-200 rounded p-6 text-center mb-4">
        <div class="text-2xl font-bold">₦{{ transferForm.data.amount.value | currency }}</div>
        <div class="text-sm text-gray-600">Amount</div>
      </div>

      <div class="border border-dashed border-blue-200 rounded p-6 mb-4" v-if="selectedBeneficiary">
        <div class="font-bold mb-4">{{ selectedBeneficiary.name }}</div>

        <div class="grid grid-cols-2 gap-3 mb-4">
          <div class="col-span-1">
            <div class="bg-blue-200 p-4 flex items-center rounded">
              <ion-icon name="wallet-outline" class="text-blue-500 mr-2"></ion-icon>
              <span class="text-xs">{{ selectedBeneficiary.account_number }}</span>
            </div>
          </div>
          <div class="col-span-1">
            <div class="bg-blue-200 p-4 flex items-center rounded">
              <ion-icon name="business-outline" class="text-blue-500 mr-2"></ion-icon>
              <span class="text-xs">{{ selectedBeneficiary.bank_id | bankName($store.state.banks) }}</span>
            </div>
          </div>
        </div>

        <div class="text-sm">
          {{ transferForm.data.description.value }}
        </div>

      </div>

      <div class="flex flex-row items-center">
        <button type="button" class="btn btn-gray-soft btn-md mr-2" @click.prevent="startTransfer" :disabled="transferForm.loading">
          Edit
        </button>
        <button type="button" class="btn btn-blue btn-md w-full" @click.prevent="confirmTransfer" :disabled="transferForm.loading">
          <span v-if="transferForm.loading">Verifying</span>
          <span v-else>Confirm</span>
        </button>
      </div>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Transfer Request Sent.
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
    </modal>
    
    <create-expense-sub-category @success="categoryAdded" ref="createExpenseSubCategory" />

  </div>
</template>

<script>
  export default {
    data() {
      return {
        beneficiaries: this.$options.resource([]),
        categories: this.$options.resource([]),
        transferForm: this.$options.basicForm(['label', 'beneficiary', 'description', 'amount', 'category', {name: 'invoice', rules: 'nullable'}]),
        beneficiaryForm: this.$options.basicForm(['name', 'bank_id', 'description', {name: 'account_number', rules: 'required|number|length:10'}]),
      }
    },
    computed: {
      banks() {
        return this.$store.state.banks.map(bank => ({ title: bank.name, value: bank.code }));
      },
      beneficiaryOptions() {
        return this.beneficiaries.data.map(beneficiary => ({ title: beneficiary.name, value: beneficiary.id }));
      },
      selectedBeneficiary() {
        return this.beneficiaries.data.find(beneficiary => beneficiary.id === this.transferForm.data.beneficiary.value);
      },
      subCategoryOptions() {
        var subCategoryOptions = [];

        this.categories.data.forEach(category => {
          subCategoryOptions = [
            ...subCategoryOptions,
            ...category.company_expense_subcategory.map(sub => ({ title: sub.label, value: sub.id }))
          ];
        });

        return subCategoryOptions;
      },
      selectedCategory() {
        return this.categories.data.find(category => category.id === this.transferForm.data.category.value);
      },
    },
    beforeMount() {
      this.getBeneficiaries();
      this.getCategories();
    },
    methods: {
      addBeneficiary() {
        this.$refs.beneficiaryModal.open();
      },
      addCategory() {
        this.$refs.createExpenseSubCategory.open();
      },
      beginProcess() {
        this.startTransfer();
      },
      startTransfer() {
        this.$refs.transferConfirmationModal.close();
        this.$refs.transferRequestModal.open();
      },
      setTransfer() {
        if (!this.validateForm(this.transferForm)) {
          return false;
        }

        this.$refs.transferRequestModal.close();
        this.$refs.transferConfirmationModal.open();
      },
      categoryAdded(category) {
        this.getCategories();
        this.transferForm.data.category.value = category.id;
      },

      async getBeneficiaries() {
        this.beneficiaries.loading = true;

        await this.sendRequest('corporate.beneficiaries.all', this.user.company_id, {
          success: response => {
            this.beneficiaries.data = response.data.beneficiaries;
          },
          error: error => {
            this.beneficiaries.error = error;
          }
        });

        this.beneficiaries.loading = false;
      },
      async getCategories() {
        this.categories.loading = true;

        await this.sendRequest('corporate.expenseCategories.all', this.user.company_id, {
          success: response => {
            this.categories.data = response.data.data || [];
          },
          error: error => {
            this.categories.error = error;
          }
        });

        this.categories.loading = false;
      },
      async submitCategory() {
        if (!this.validateForm(this.categoryForm)) {
          return false;
        }

        this.categoryForm.loading = true;
        await this.sendRequest(`corporate.expenseCategories.new`, {
          data: {
            ...this.getFormData(this.categoryForm),
            company_id: this.user.company_id,
          },
          success: response => {
            this.transferForm.data.category.value = response.data.category.id;
            this.categories.data.push(response.data.category);
            this.$refs.beneficiaryModal.close();
            this.categoryForm = this.resetForm(this.categoryForm);
            this.getBeneficiaries();
          },
          error: error => {
            this.categoryForm.error = error;
            this.mapFormErrors(this.categoryForm, error?.response?.data?.errors);
            console.log(error);
          }
        })
        this.categoryForm.loading = false;
      },
      async confirmTransfer() {
        this.transferForm.loading = true;

        await this.sendRequest('corporate.transfers.new', {
          data: {
            company_id: this.user.company_id,
            beneficiary_id: this.transferForm.data.beneficiary.value,
            label: this.transferForm.data.label.value,
            amount: this.transferForm.data.amount.value,
            description: this.transferForm.data.description.value,
            expense_category_id: this.transferForm.data.category.value,
            department_id: 1
          },
          success: () => {
            this.$refs.transferRequestModal.close();
            this.$refs.transferConfirmationModal.close();
            this.$refs.successModal.open();
            this.transferForm = this.resetForm(this.transferForm);
          },
          error: error => {
            this.transferForm.error = error;
            this.startTransfer();
            this.mapFormErrors(this.transferForm, error?.response?.data?.errors);
          }
        });

        this.transferForm.loading = false;
      },
      async submitBeneficiary() {
        if (!this.validateForm(this.beneficiaryForm)) {
          return false;
        }

        this.beneficiaryForm.loading = true;
        await this.sendRequest(`corporate.beneficiaries.new`, {
          data: {
            ...this.getFormData(this.beneficiaryForm),
            company_id: this.user.company_id,
          },
          success: response => {
            this.transferForm.data.beneficiary.value = response.data.beneficiary.id;
            this.beneficiaries.data.push(response.data.beneficiary);
            this.$refs.beneficiaryModal.close();
            this.beneficiaryForm = this.resetForm(this.beneficiaryForm);
            this.getBeneficiaries();
          },
          error: error => {
            this.beneficiaryForm.error = error;
            this.mapFormErrors(this.beneficiaryForm, error?.response?.data?.errors);
            console.log(error);
          }
        })
        this.beneficiaryForm.loading = false;
      },
    }
  }
</script>